import React, { useState, useContext } from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Container, Tab, Nav, Row, Col, ListGroup } from "react-bootstrap"
import { graphql, Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import GlobalContext from "../contexts/GlobalContext"

import { Box } from "../components/Core"
import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Text } from "../components/Core"
import { slugCat, slugSubCat, slugHome } from "../utils/slugs"

import renderSlices from "../components/Slices"
import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg3.jpg"

const PageWrapperStyled = styled(PageWrapper)`
  .breadcrumb-link {
    font-weight: 600;
    color: ${({ theme, colorBrand }) =>
      colorBrand ? colorBrand : theme.colors.primary} !important;

    &:hover,
    &:active,
    &:focus,
    &.active {
      text-decoration: none !important;
      outline: none !important;
      color: ${({ theme, colorBrand }) =>
        colorBrand ? colorBrand : theme.colors.primary} !important;
    }
  }

  article a,
  table tr td p a {
    color: ${({ theme, colorHover }) =>
      colorHover ? colorHover : theme.colors.secondary} !important;
    transition: all 0.15s ease-out;
    user-select: none;
    text-decoration: underline !important;
    &:hover,
    &:active,
    &:focus,
    &.active {
      text-decoration: underline !important;
      outline: none !important;
      color: ${({ theme, colorHover }) =>
        colorHover ? colorHover : theme.colors.secondary} !important;
    }
  }

  .list-group-item,
  .list-group-item.disable-active.active,
  .accordion-card,
  .accordion-card > .card-header {
    background: ${({ theme }) => theme.colors.lightShade} !important;
    transition: all 0.2s ease-out;
    border-color: ${({ theme }) => theme.colors.border} !important;
    user-select: none;
  }
  .list-group-item,
  .list-group-item.disable-active.active a {
    color: ${({ theme }) => theme.colors.text} !important;
    &:hover,
    &:active,
    &:focus,
    &.active {
      text-decoration: none;
      outline: none !important;
      color: ${({ theme }) => theme.colors.text} !important;
    }
  }

  .accordion-card > .card-header {
    font-weight: 600;
  }

  .list-group-item:not(.active):hover,
  .card-body,
  .accordion-card:not(.active) > .card-header:hover {
    color: ${({ theme }) => theme.colors.dark} !important;
    background-color: #ffffff !important;
  }

  .list-group-item.active,
  .accordion-card.active > .card-header {
    color: #fff !important;
    background: ${({ theme, colorBrand }) =>
      colorBrand ? colorBrand : theme.colors.primary} !important;
    border-color: ${({ theme }) => theme.colors.border} !important;
  }
`

const Category = ({ data }) => {
  const { pathname } = useLocation()
  const [tabActiveKey, setTabActiveKey] = useState("sub-cats")
  const gContext = useContext(GlobalContext)

  const cat = data.prismicCategory
  const allSubCats = data.allPrismicSubCategory.nodes.filter(
    ({ data }) => data.category.uid === cat.uid
  )

  const { body: slices = [], color_brand, color_hover, color_active } = cat.data

  const { category_info_title, sub_cats_title } = cat.data

  const { btn_back, btn_home, btn_request_sample } = data.prismicCommon?.data

  return (
    <>
      <GatsbySeo
        title={cat.data.meta_title?.text || cat.data.title?.text}
        description={cat.data.meta_description?.text}
      />
      <PageWrapperStyled
        colorBrand={color_brand}
        colorActive={color_active}
        colorHover={color_hover}
      >
        <Hero bgImg={cat.data.cover_image.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {cat.data.title && (
                  <Title variant="hero" color="light">
                    {cat.data.title.text}
                  </Title>
                )}
                <Box mt={4}>
                  <div className="d-flex my-3 justify-content-center">
                    <div className="text-white font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="text-white font-weight-bold"
                      >
                        {btn_home && btn_home.text.length > 0
                          ? btn_home.text
                          : "Home"}
                      </Link>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9">
                <Tab.Container
                  activeKey={tabActiveKey}
                  activeKey={tabActiveKey}
                >
                  <Row>
                    <Col md={3} className="mb-5 mb-lg-0">
                      <>
                        <div className="d-flex my-3 justify-content-start">
                          <div className="text-white font-weight-bold ml-2">
                            <Link
                              to={slugHome(gContext.currentLang)}
                              className="text-dark font-weight-bold"
                            >
                              {"<<"}{" "}
                              {btn_back && btn_back.text.length > 0
                                ? btn_back.text
                                : "Back"}
                            </Link>
                          </div>
                        </div>
                      </>

                      <ListGroup
                        css={`
                          .list-group-flush:first-child,
                          .list-group-item:first-child {
                            border-top: 1px solid rgba(24, 59, 86, 0.115);
                          }
                          .active.nav-link {
                            color: white !important;
                          }
                        `}
                      >
                        <ListGroup.Item
                          as={Nav.Link}
                          className={tabActiveKey === "info" ? "active" : ""}
                          href={slugCat(cat.uid, gContext.currentLang)}
                          onClick={e => {
                            e.preventDefault()
                            navigate(slugCat(cat.uid, gContext.currentLang))
                          }}
                        >
                          {category_info_title &&
                          category_info_title.text.length > 0
                            ? category_info_title.text
                            : "Cat Info"}
                        </ListGroup.Item>

                        {allSubCats.length > 0 && (
                          <ListGroup.Item
                            as={Nav.Link}
                            className={
                              tabActiveKey === "sub-cats" ? "active" : ""
                            }
                            onClick={() => setTabActiveKey("sub-cats")}
                          >
                            {sub_cats_title && sub_cats_title.text.length > 0
                              ? sub_cats_title.text
                              : "Sub Categories"}
                            <small>({allSubCats.length})</small>
                          </ListGroup.Item>
                        )}

                        <ListGroup.Item
                          as={AnchorLink}
                          to={`${pathname}#support`}
                          stripHash
                        >
                          {btn_request_sample &&
                          btn_request_sample.text.length > 0
                            ? btn_request_sample.text
                            : "Request Sample"}
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={9}>
                      <Box mb={4}>
                        <div className="d-flex my-3 justify-content-left">
                          <div className="font-weight-bold">
                            {">>"}
                            <Link
                              to={slugHome(gContext.currentLang)}
                              className="breadcrumb-link"
                            >
                              {btn_home && btn_home.text.length > 0
                                ? btn_home.text
                                : "Home"}
                            </Link>
                          </div>
                          <div className="font-weight-bold ml-2">
                            <div>
                              {" >> "}
                              <a
                                href="#"
                                className="breadcrumb-link"
                                onClick={e => {
                                  e.preventDefault()
                                  setTabActiveKey("info")
                                }}
                              >
                                {cat.data.title.text}
                              </a>
                            </div>
                          </div>
                        </div>
                      </Box>
                      <Tab.Content>
                        <Tab.Pane eventKey="info">
                          {slices.length > 0 ? (
                            slices.map(({ slice_type, primary, items }, i) =>
                              renderSlices(
                                slice_type,
                                primary,
                                items,
                                `key_${i}`
                              )
                            )
                          ) : (
                            <>
                              {cat.data.intro.text && (
                                <p className="my-3">{cat.data.intro.text}</p>
                              )}
                            </>
                          )}
                        </Tab.Pane>
                        {allSubCats.length > 0 && (
                          <Tab.Pane eventKey="sub-cats">
                            <ul>
                              {allSubCats.map(cat => (
                                <li key={cat.uid}>
                                  <Link
                                    to={slugSubCat(
                                      cat.uid,
                                      cat.data.category.uid,
                                      gContext.currentLang
                                    )}
                                    className="link-subcats"
                                  >
                                    {cat.data.title.text}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapperStyled>
    </>
  )
}
export default Category

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismicCommon(lang: { eq: $lang }) {
      data {
        btn_back {
          text
        }
        btn_home {
          text
        }
        btn_request_sample {
          text
        }
      }
    }

    allPrismicSubCategory(
      sort: { fields: data___order, order: ASC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        uid
        lang
        data {
          title {
            text
          }
          category {
            uid
          }
        }
      }
    }

    prismicCategory(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      lang
      data {
        title {
          text
        }
        intro {
          text
        }
        cover_image {
          url
        }

        color_brand
        color_hover
        color_active

        category_info_title {
          text
        }
        sub_cats_title {
          text
        }

        meta_title {
          text
        }
        meta_description {
          text
        }

        body {
          ... on PrismicCategoryBodySection {
            slice_type
            items {
              div {
                raw
              }
            }
            primary {
              title1 {
                text
              }
            }
          }
          ... on PrismicCategoryBodyImageGallery {
            slice_type
            primary {
              title1 {
                text
              }
            }
            items {
              caption {
                text
              }
              image {
                url
              }
            }
          }
        }
      }
    }
  }
`
